@charset "utf-8";

// Bootstrap configuration
$enable-rounded: true;
$enable-gradients: true;

// Theme colors
$astronaut: #2c4d76; // A dark navy. Used as primary text color and dark background color.
$havelock: #4e8ad4; // A strong, punchy blue. Suitable for primary actions.
$mantis: #58b55c; // A strong, punchy green. Suitable for primary actions that feel "positive" or "completing".
$athens: #e1e6ea; // A soft, blue-tinged, light grey. Suitable for non-primary actions.
$porcelain: #f6f7f8; // A subtle off-white. Suitable for contrasting sections against white.

// Text emphasis colors
$shout-color: rgba(65, 59, 84, 1);
$speak-color: rgba(65, 59, 84, 0.8);
$whisper-color: rgba(65, 59, 84, 0.6);
$deselected-color: rgba(65, 59, 84, 0.33);

// Assign theme colors to bootstrap variables
$primary: #1ca683;
$secondary: #846ae3;
$success: $mantis;
$light: $porcelain;
$dark: $astronaut;
$body-color: $speak-color;
$headings-color: $shout-color;
$link-color: #846ae3;
$theme-colors: (
  "astronaut": $astronaut,
  "mantis": $mantis,
  "havelock": $havelock
);
$border-color: rgba(black, 0.1);
$table-accent-bg: $porcelain;

$box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 32, 0.1);
$box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 32, 0.1);
$box-shadow-lg: 0 0.75rem 1.5rem rgba(0, 0, 32, 0.1);

$navbar-light-color: $whisper-color;
$navbar-light-active-color: $shout-color;
$navbar-light-hover-color: #1ca683;

$navbar-dark-color: rgba(white, 0.5);
$navbar-dark-active-color: white;
$navbar-dark-hover-color: white;

$small-font-size: 0.875rem;

$badge-font-size: 0.875rem;
$badge-border-radius: 3px;

$font-family-sans-serif: 'Quicksand', sans-serif !default;
$font-family-monospace: "Inconsolata", monospace !default;

@import "~bootstrap/scss/bootstrap";

body {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

h1,
.h1 {
  font-size: 2.5rem;
  line-height: 1.5;

  @include media-breakpoint-down(sm) {
    font-size: 2rem;
  }
}

h2,
.h2 {
  font-size: 2rem;
  line-height: 1.5;

  @include media-breakpoint-down(sm) {
    font-size: 1.75rem;
  }
}

h3,
.h3 {
  font-size: 1.75rem;
  line-height: 1.5;

  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
  }
}

h4,
.h4 {
  font-size: 1.5rem;
  line-height: 1.5;

  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
  line-height: 1.5;

  @include media-breakpoint-down(sm) {
    font-size: 1.125rem;
  }
}

h6,
.h6 {
  font-size: 1rem;
  line-height: 1.5;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}

html,
body,
#root {
  height: 100vh;
}
