/* stylelint-disable */
.react-datepicker__triangle {
  left: -0.5rem !important;
}

.react-datepicker-popper {
  inset: auto !important;
  right: 1rem !important;
  transform: none !important;
}
